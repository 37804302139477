import { BrowserRouter } from "react-router-dom";
import { createTheme, ThemeProvider } from "@mui/material/styles";
import { SnackbarProvider } from "notistack";

import LoadingScreen from "components/LoadingScreen";
import ScrollToTop from "components/ScrollToTop";
import Router from "router/Router";
import useAuth from "hooks/useAuth";

const theme = createTheme({
  palette: {
    primary: {
      main: "rgb(10, 77, 104)",
      light: "rgba(201, 228, 233, 1)",
      dark: "rgba(8, 131, 149, 1)",
    },
    secondary: {
      main: "#f44336",
    },
  },
  typography: {
    button: {
      textTransform: "none",
    },
  },
});

function App() {
  const { loading } = useAuth();

  return (
    <ThemeProvider theme={theme}>
      <BrowserRouter>
        <SnackbarProvider autoHideDuration={3000}>
          <ScrollToTop />
          {loading ? <LoadingScreen /> : <Router />}
        </SnackbarProvider>
      </BrowserRouter>
    </ThemeProvider>
  );
}

export default App;
