import { Outlet } from "react-router-dom";
import { Stack, Box } from "@mui/material";

import coverImg from "assets/cover2.png";
import logo from "assets/logo.png";

export default function AuthLayout() {
  return (
    <Stack height="100vh" direction="row">
      <Stack width="50%">
        <Outlet />
      </Stack>
      <Stack width="50%">
        <Box
          height="100vh"
          display="flex"
          flexDirection="column"
          justifyContent="center"
          alignItems="center"
          sx={{
            backgroundImage: `url(${coverImg})`,
            backgroundPosition: "center",
            backgroundSize: "cover",
          }}
        >
          <Stack alignItems="center" px={10} spacing={25}>
            <Stack alignItems="center">
              <Box
                component="img"
                src={logo}
                sx={{
                  width: "200px",
                  height: "100px",
                  flexShrink: 0,
                }}
              />
              <Box sx={{ mt: 2 }}>
                <Box
                  sx={{
                    color: "#FFF",
                    textAlign: "center",
                    textShadow: "0px 2px 5px rgba(0, 0, 0, 0.05)",
                    fontFamily: "Inter",
                    fontSize: "25px",
                    fontStyle: "normal",
                    fontWeight: 400,
                    lineHeight: "30px",
                    letterSpacing: "0.2px",
                  }}
                >
                  Welcome to
                </Box>
                <Box
                  sx={{
                    color: "#FFF",
                    fontFamily: "Inter",
                    fontSize: "35px",
                    fontStyle: "normal",
                    fontWeight: "500",
                    lineHeight: "50px",
                    letterSpacing: "0.2px",
                  }}
                >
                  On Demand Drivers
                </Box>
              </Box>
            </Stack>
            <Stack spacing={2}>
              <Box
                sx={{
                  color: "#FFF",
                  textAlign: "center",
                  textShadow: "0px 2px 5px rgba(0, 0, 0, 0.05)",
                  fontSize: "19px",
                  fontStyle: "normal",
                  fontWeight: "400",
                  lineHeight: "36px",
                }}
              >
                Looking for a reliable car driver service in your area?
              </Box>
              <Box
                sx={{
                  color: "#FFF",
                  textAlign: "center",
                  textShadow: "0px 2px 5px rgba(0, 0, 0, 0.05)",
                  fontSize: "21px",
                  fontStyle: "normal",
                  fontWeight: "700",
                  lineHeight: "36px",
                }}
              >
                Look no further! Our expert drivers are available in all over
                Kerala to get you where you need to go safely and on time.
              </Box>
            </Stack>
          </Stack>
        </Box>
      </Stack>
    </Stack>
  );
}
