import { useState, useRef } from "react";
import { Outlet } from "react-router-dom";
import { Box, Stack, Typography, Avatar, Button, Divider } from "@mui/material";

import AdminBreadcrumb from "components/breadCrumb/AdminBreadCrumb";
import MenuPopover from "components/MenuPopover";
import useAuth from "hooks/useAuth";

import logo from "assets/logo2.png";
import defaultUser from "assets/default-user.png";
import { ReactComponent as MenuIcon } from "assets/menu-icon.svg";
import { ReactComponent as DownArrow } from "assets/down-arrow.svg";
import Notifications from "./Notifications";

export default function AppLayout() {
  const { user, logout } = useAuth();

  const [open, setOpen] = useState(false);
  const anchorRef = useRef(null);

  const handleOpen = () => {
    setOpen(true);
  };
  const handleClose = () => {
    setOpen(false);
  };

  const handleLogout = async () => {
    logout();
  };

  return (
    <Stack height="100vh">
      <Stack
        sx={{
          width: "120px",
          height: "110px",
          padding: "28px 10px 28px 10px",
          borderRadius: "0px 0px 10px 0px",
          boxShadow: "0px 7px 12px 0px rgba(53, 31, 11, 0.23)",
          position: "absolute",
          display: "flex",
          alignItems: "center",
          justifyContent: "center",
          background: "#fff",
        }}
      >
        <Box
          component="img"
          src={logo}
          sx={{
            width: 100,
            height: 55,
            cursor: "pointer",
          }}
        />
      </Stack>
      <Stack
        sx={{
          height: "90px",
          padding: "10px 32px 10px 32px",
          paddingLeft: "140px",
        }}
        direction="row"
        justifyContent="space-between"
        alignItems="center"
      >
        <AdminBreadcrumb />
        <Stack direction="row" gap={2}>
          <Notifications />
          <Stack
            sx={{
              width: 2,
              background: "rgba(230, 230, 230, 1)",
            }}
          />
          <Stack direction="row" gap={2} alignItems="center">
            <Stack>
              <Avatar
                alt={user.fullName}
                src={user.profilePic ?? defaultUser}
              />
            </Stack>
            <Stack>
              <Box
                sx={{
                  fontFamily: "Inter",
                  fontSize: "10px",
                  fontWeight: 400,
                }}
              >
                Welcome,
              </Box>
              <Box
                sx={{
                  fontSize: "14px",
                  fontWeight: 600,
                }}
              >
                {user.fullName}
              </Box>
            </Stack>
            <Stack
              alignItems="center"
              justifyContent="center"
              sx={{ cursor: "pointer" }}
              onClick={handleOpen}
              ref={anchorRef}
            >
              <DownArrow stroke="rgba(34, 34, 34, 1)" />
            </Stack>
          </Stack>
          <MenuPopover
            open={open}
            onClose={handleClose}
            anchorEl={anchorRef.current}
            sx={{ width: 220 }}
          >
            <Box sx={{ my: 1.5, px: 2.5 }}>
              <Typography variant="subtitle1" noWrap>
                {user.fullName}
              </Typography>
              <Typography
                variant="body2"
                sx={{ color: "text.secondary" }}
                noWrap
              >
                {user?.email}
              </Typography>
            </Box>

            <Divider sx={{ my: 1 }} />

            <Box sx={{ p: 2, pt: 1.5 }}>
              <Button
                fullWidth
                color="inherit"
                variant="outlined"
                onClick={handleLogout}
              >
                Logout
              </Button>
            </Box>
          </MenuPopover>
        </Stack>
      </Stack>
      <Stack flex={1} direction="row" sx={{ overflow: "auto" }}>
        <Stack
          sx={{
            width: "100px",
            padding: "48px 8px 32px 8px",
            paddingTop: "40px",
            gap: "16px",
          }}
        >
          <Stack gap={1} sx={{ cursor: "pointer" }} alignItems="center">
            <Stack
              sx={{
                padding: "15px",
                borderRadius: "10px",
                background: "rgba(10, 77, 104, 1)",
                display: "flex",
                alignItems: "center",
                justifyContent: "center",
                flex: 0,
              }}
            >
              <MenuIcon stroke="rgba(255, 255, 255, 1)" />
            </Stack>
            <Typography
              sx={{
                fontFamily: "Inter",
                fontSize: "12px",
                fontWeight: 600,
                textAlign: "center",
                color: "rgba(10, 77, 104, 1)",
              }}
            >
              Dashboard
            </Typography>
          </Stack>
          <Stack gap={1} sx={{ cursor: "pointer" }} alignItems="center">
            <Stack
              sx={{
                padding: "15px",
                borderRadius: "10px",
                display: "flex",
                alignItems: "center",
                justifyContent: "center",
                flex: 0,
              }}
            >
              <MenuIcon stroke="rgba(102, 112, 133, 1)" />
            </Stack>
            <Typography
              sx={{
                fontFamily: "Inter",
                fontSize: "12px",
                fontWeight: 600,
                textAlign: "center",
                color: "rgba(102, 112, 133, 1)",
              }}
            >
              Financial Management
            </Typography>
          </Stack>
          <Stack gap={1} sx={{ cursor: "pointer" }} alignItems="center">
            <Stack
              sx={{
                padding: "15px",
                borderRadius: "10px",
                display: "flex",
                alignItems: "center",
                justifyContent: "center",
                flex: 0,
              }}
            >
              <MenuIcon stroke="rgba(102, 112, 133, 1)" />
            </Stack>
            <Typography
              sx={{
                fontFamily: "Inter",
                fontSize: "12px",
                fontWeight: 600,
                textAlign: "center",
                color: "rgba(102, 112, 133, 1)",
              }}
            >
              Asset Management
            </Typography>
          </Stack>
        </Stack>
        <Stack flex={1} sx={{ overflow: "auto" }}>
          <Stack
            sx={{
              background: "#f6f8f8",
              flex: 1,
              overflow: "auto",
              padding: 3,
              pb: 1,
            }}
          >
            <Outlet />
          </Stack>
          <Stack sx={{ background: "#001a24" }}>
            <Stack
              sx={{
                background: "rgba(255, 255, 255, 0.19)",
              }}
              px={8}
              py={1}
            >
              <Typography
                sx={{
                  color: "rgba(255, 255, 255, 0.75)",
                  textAlign: "left",
                  fontSize: "12px",
                  fontStyle: "normal",
                  fontWeight: 400,
                }}
              >
                © Copyright 2024 - All rights Reserved - On Demand Drivers
              </Typography>
            </Stack>
          </Stack>
        </Stack>
      </Stack>
    </Stack>
  );
}
