import { axiosPlain } from "../utils/axios";

export async function signUpRequest(payload) {
  try {
    const response = await axiosPlain.post("/auth/signup", payload);
    // console.warn(response.data.data.otp);
    return {
      success: true,
      data: response.data.data,
    };
  } catch (error) {
    console.error(JSON.stringify(error.response.data.message));
    const errorMsg = Object.values(error.response?.data?.errors).join("\n");
    return {
      success: false,
      message: errorMsg || error.response?.data?.message || error.message,
    };
  }
}

export async function verifyOtpRequest(payload) {
  try {
    const response = await axiosPlain.post("/auth/verifyOtp", payload);
    return {
      success: true,
      data: response.data.data,
    };
  } catch (error) {
    console.error(JSON.stringify(error.response.data.message));
    return {
      success: false,
      message: error.response?.data?.message || error.message,
    };
  }
}

export async function loginRequest(payload) {
  try {
    const response = await axiosPlain.post("/admin/auth/login", payload);
    return {
      success: true,
      data: response.data.data,
    };
  } catch (error) {
    return {
      success: false,
      message: error.response?.data?.message || error.message,
    };
  }
}

export async function generateOtp(payload) {
  try {
    const response = await axiosPlain.post("/auth/generateOtp", payload);
    return {
      success: true,
      data: response.data.data,
    };
  } catch (error) {
    return {
      success: false,
      message: error.response?.data?.message || error.message,
    };
  }
}

export async function getUserDetails(token) {
  try {
    const response = await axiosPlain.get("/admin/user", {
      headers: {
        Authorization: token,
      },
    });
    return {
      success: true,
      data: response.data.data,
    };
  } catch (error) {
    console.error(JSON.stringify(error.response.data.message));
    return {
      success: false,
      message: error.response?.data?.message || error.message,
    };
  }
}
