import { axiosApp } from "utils/appAxios";

export async function getNotificationsDetails(params = {}) {
  try {
    const request = await axiosApp.get(`/admin/notifications`, params);

    return {
      success: true,
      data: request.data.data.rows,
      count: request.data.data.count,
    };
  } catch (error) {
    return {
      success: false,
      message: error.message,
    };
  }
}
