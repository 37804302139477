import { Suspense, lazy } from "react";
import { useRoutes, Navigate } from "react-router-dom";

import LoadingScreen from "components/LoadingScreen";
import AuthLayout from "layouts/AuthLayout";
import AppLayout from "layouts/AppLayout";
import AdminAppLayout from "layouts/AdminAppLayout";
import AdminDashboardLayout from "layouts/AdminDashboardLayout";
import AuthGuard from "components/AuthGuard";

const Loadable = (Component) => (props) => {
  return (
    <Suspense
      fallback={
        <LoadingScreen
        //   sx={{
        //     top: 0,
        //     left: 0,
        //     width: 1,
        //     zIndex: 9999,
        //     position: "fixed",
        //   }}
        />
      }
    >
      <Component {...props} />
    </Suspense>
  );
};

export default function Router() {
  return useRoutes([
    {
      path: "/",
      element: <Navigate to="/admin/dashboard/summary" replace />,
    },
    {
      path: "auth",
      element: <AuthLayout />,
      children: [
        {
          path: "login",
          element: <Login />,
        },
        { path: "sign-up", element: <SignUp /> },
        // { path: "forgot-password", element: <ForgotPassword /> },
      ],
    },
    {
      path: "app",
      element: <AppLayout />,
      children: [
        {
          path: "home",
          element: <Home />,
        },
        {
          path: "trips",
          element: <Trips />,
        },
        {
          path: "about-us",
          element: <AboutUs />,
        },
        {
          path: "profile",
          element: <Profile />,
        },
        {
          path: "booking",
          element: <Booking />,
        },
      ],
    },
    {
      path: "admin",
      element: (
        <AuthGuard>
          <AdminAppLayout />
        </AuthGuard>
      ),
      children: [
        {
          path: "",
          element: <Navigate to="/admin/dashboard/summary" replace />,
          exact: true,
        },
        {
          path: "dashboard",
          element: <AdminDashboardLayout />,
          children: [
            {
              path: "",
              element: <Navigate to="/admin/dashboard/summary" replace />,
            },
            {
              path: "summary",
              element: <SummaryPage />,
            },
            {
              path: "customers",
              element: <AdminCustomers />,
            },
            {
              path: "trips",
              element: <AdminTrips />,
            },
            {
              path: "services",
              element: <AdminServices />,
              // children: [
              //   {
              //     path: "",
              //     element: <AdminServices />,
              //   },
              //   {
              //     path: "cabBook",
              //     element: <AdminServicesCabBook />,
              //   },
              //   {
              //     path: "carWash",
              //     element: <AdminServicesCarWash />,
              //   },
              // ],
            },
            { path: "cabs", element: <AdminCabsList /> },
            {
              path: "drivers",
              element: <AdminDrivers />,
            },
          ],
        },
        {
          path: "trip",
          children: [
            {
              path: ":tripId",
              element: <AdminTripDetails />,
              exact: true,
            },
          ],
        },
        {
          path: "carwash",
          children: [
            {
              path: ":bookingId",
              element: <AdminCarWashBookingDetails />,
              exact: true,
            },
          ],
        },
        {
          path: "cabBook",
          children: [
            {
              path: ":bookingId",
              element: <AdminCabBookingDetails />,
              exact: true,
            },
          ],
        },
        {
          path: "customer",
          children: [
            {
              path: ":customerId",
              element: <AdminCustomerDetails />,
              exact: true,
            },
          ],
        },
        {
          path: "customer",
          children: [
            {
              path: ":customerId/trips",
              element: <AdminTrips />,
              exact: true,
            },
          ],
        },
        {
          path: "cabs",
          children: [
            {
              path: "",
              element: <AdminCabsList />,
              exact: true,
            },
          ],
        },
      ],
    },

    // {
    //   path: "app",
    //   //   element: (
    //   //     <AuthGuard>
    //   //       <DashboardLayout />
    //   //     </AuthGuard>
    //   //   ),
    //   //   children: [
    //   //     { path: "/", element: <Navigate to="/app/dashboard" replace /> },
    //   //   ],
    // },

    // { path: "/", element: <Navigate to="/app" replace /> },
    // { path: "*", element: <Navigate to="/404" replace /> },
    {
      path: "*",
      element: <Navigate to="/admin/dashboard/summary" replace />,
    },
  ]);
}

const Login = Loadable(lazy(() => import("../components/login/Login")));
const SignUp = Loadable(lazy(() => import("../components/signUp/SignUp")));

const Home = Loadable(lazy(() => import("../components/home/Home")));
const Trips = Loadable(lazy(() => import("../components/trips/Trips")));
const AboutUs = Loadable(lazy(() => import("../components/aboutUs/AboutUs")));
const Profile = Loadable(lazy(() => import("../components/profile/Profile")));
const Booking = Loadable(lazy(() => import("../components/bookings/Booking")));

const AdminCustomers = Loadable(
  lazy(() => import("../admin/pages/customers/Customers"))
);
const SummaryPage = Loadable(
  lazy(() => import("../admin/pages/summary/Summary"))
);
const AdminTrips = Loadable(lazy(() => import("../admin/pages/trips/Trips")));
const AdminServices = Loadable(
  lazy(() => import("../admin/pages/services/Services"))
);
const AdminCarWashBookingDetails = Loadable(
  lazy(() => import("../admin/pages/services/CarWashBookingDetails"))
);
const AdminCabBookingDetails = Loadable(
  lazy(() => import("../admin/pages/services/CabBookDetails"))
);
const AdminDrivers = Loadable(
  lazy(() => import("../admin/pages/drivers/Drivers"))
);

const AdminTripDetails = Loadable(
  lazy(() => import("../admin/pages/trips/TripDetails"))
);
const AdminCustomerDetails = Loadable(
  lazy(() => import("../admin/pages/customers/CustomerDetails"))
);
const AdminCabsList = Loadable(
  lazy(() => import("../admin/pages/cabs/CabsList"))
);

// const ResetPassword = Loadable(
//   lazy(() => import("../pages/authentication/ResetPassword"))
// );
// const ForgotPassword = Loadable(
//   lazy(() => import("../pages/authentication/ForgotPassword"))
// );
