import { Stack, Typography } from "@mui/material";

import "./footer.css";

export default function Footer() {
  return (
    <Stack className="contacts-section">
      <Stack px={8} py={5}></Stack>
      <Stack
        sx={{
          background: "rgba(255, 255, 255, 0.19)",
        }}
        px={8}
        py={1}
      >
        <Typography className="copyright">
          © Copyright 2023 - All rights Reserved - On Demand Drivers
        </Typography>
      </Stack>
    </Stack>
  );
}
