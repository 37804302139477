import * as Yup from "yup";
import {
  Stack,
  Alert,
  Box,
  Typography,
  TextField,
  Checkbox,
  FormControlLabel,
} from "@mui/material";
import { LoadingButton } from "@mui/lab";
import { useFormik } from "formik";

import coverImg from "assets/cover2.png";
import logo from "assets/logo.png";
import img from "assets/loginlayout.png";
import useAuth from "hooks/useAuth";

const LoginSchema = Yup.object().shape({
  username: Yup.string().required("Username is required"),
  password: Yup.string().required("Password is required"),
});

export default function LoginPage() {
  const { login } = useAuth();

  const { errors, touched, isSubmitting, handleSubmit, getFieldProps, values } =
    useFormik({
      initialValues: {
        username: "",
        password: "",
        rememberMe: true,
      },
      validationSchema: LoginSchema,
      onSubmit: async (values, { setErrors, setSubmitting, resetForm }) => {
        const { username, password, rememberMe } = values;
        const { success, message } = await login(
          {
            username,
            password,
          },
          rememberMe
        );
        if (!success) {
          resetForm();
          setSubmitting(false);
          setErrors({
            afterSubmit: message,
          });
        }
      },
    });

  return (
    <Stack height="100vh" direction="row">
      <Stack width="50%">
        <Stack justifyContent="center" height="100%">
          <Stack px={18} py={6}>
            <Box
              component="img"
              src={img}
              sx={{
                width: "103px",
                height: "95px",
                flexShrink: 0,
              }}
            />
            <Stack spacing="10px">
              <Typography
                sx={{
                  color: "#101828",
                  fontFamily: "Inter",
                  fontSize: "30px",
                  fontStyle: "normal",
                  fontWeight: "600",
                  lineHeight: "30px",
                  letterSpacing: "-0.72px",
                }}
              >
                Login
              </Typography>
              <Typography
                sx={{
                  color: "#344054",
                  fontFamily: "Inter",
                  fontSize: "15px",
                  fontStyle: "normal",
                  fontWeight: "500",
                  lineHeight: "28px",
                  letterSpacing: "-0.36px",
                }}
              >
                Provide your credentials to proceed, please.
              </Typography>
              {errors.afterSubmit && (
                <Alert severity="error">{errors.afterSubmit}</Alert>
              )}
              <Stack direction="row" spacing={3} alignItems="center"></Stack>
              <Stack spacing={1}>
                <Typography
                  sx={{
                    color: "#344054",
                    fontSize: "14px",
                    fontStyle: "normal",
                    fontWeight: 500,
                  }}
                >
                  Username
                </Typography>
                <TextField
                  fullWidth
                  size="small"
                  {...getFieldProps("username")}
                  error={Boolean(touched.username && errors.username)}
                  helperText={touched.username && errors.username}
                />
              </Stack>
              <Stack spacing={1}>
                <Typography
                  sx={{
                    color: "#344054",
                    fontSize: "14px",
                    fontStyle: "normal",
                    fontWeight: 500,
                  }}
                >
                  Password
                </Typography>
                <TextField
                  fullWidth
                  type="password"
                  size="small"
                  {...getFieldProps("password")}
                  error={Boolean(touched.password && errors.password)}
                  helperText={touched.password && errors.password}
                />
              </Stack>
              <Stack
                direction="row"
                justifyContent="space-between"
                alignItems="center"
              >
                <Stack direction="row" alignItems="center">
                  <FormControlLabel
                    control={
                      <Checkbox
                        name="remember-me"
                        {...getFieldProps("rememberMe")}
                        checked={values.rememberMe}
                      />
                    }
                    label={
                      <Typography
                        sx={{
                          color: "#344054",
                          fontSize: "14px",
                          fontStyle: "normal",
                          fontWeight: 500,
                        }}
                        htmlFor="remember-me"
                      >
                        Remember me
                      </Typography>
                    }
                  />
                </Stack>
                <Typography
                  sx={{
                    color: "#36f",
                    fontSize: "14px",
                    fontStyle: "normal",
                    fontWeight: 500,
                    cursor: "pointer",
                  }}
                >
                  Forgot Password?
                </Typography>
              </Stack>
              <Stack>
                <LoadingButton
                  sx={{
                    display: "flex",
                    padding: "8px 18px",
                    justifyContent: "center",
                    alignItems: "center",
                    gap: "8px",
                    borderRadius: "8px",
                    background: "#088395",
                    color: "#fff",
                    fontSize: "14px",
                    fontStyle: "normal",
                    fontWeight: 500,
                    textTransform: "none",
                    ":hover": {
                      bgcolor: "#00a7bf",
                      // color: "black",
                    },
                  }}
                  onClick={handleSubmit}
                  loading={isSubmitting}
                >
                  Login
                </LoadingButton>
              </Stack>
            </Stack>
          </Stack>
        </Stack>
      </Stack>
      <Stack width="50%">
        <Box
          height="100vh"
          display="flex"
          flexDirection="column"
          justifyContent="center"
          alignItems="center"
          sx={{
            backgroundImage: `url(${coverImg})`,
            backgroundPosition: "center",
            backgroundSize: "cover",
          }}
        >
          <Stack alignItems="center" px={10} spacing={25}>
            <Stack alignItems="center">
              <Box
                component="img"
                src={logo}
                sx={{
                  width: "200px",
                  height: "100px",
                  flexShrink: 0,
                }}
              />
              <Box sx={{ mt: 2 }}>
                <Box
                  sx={{
                    color: "#FFF",
                    textAlign: "center",
                    textShadow: "0px 2px 5px rgba(0, 0, 0, 0.05)",
                    fontFamily: "Inter",
                    fontSize: "25px",
                    fontStyle: "normal",
                    fontWeight: 400,
                    lineHeight: "30px",
                    letterSpacing: "0.2px",
                  }}
                >
                  Welcome to
                </Box>
                <Box
                  sx={{
                    color: "#FFF",
                    fontFamily: "Inter",
                    fontSize: "35px",
                    fontStyle: "normal",
                    fontWeight: "500",
                    lineHeight: "50px",
                    letterSpacing: "0.2px",
                  }}
                >
                  On Demand Drivers
                </Box>
              </Box>
            </Stack>
            <Stack spacing={2}>
              <Box
                sx={{
                  color: "#FFF",
                  textAlign: "center",
                  textShadow: "0px 2px 5px rgba(0, 0, 0, 0.05)",
                  fontSize: "19px",
                  fontStyle: "normal",
                  fontWeight: "400",
                  lineHeight: "36px",
                }}
              >
                Looking for a reliable car driver service in your area?
              </Box>
              <Box
                sx={{
                  color: "#FFF",
                  textAlign: "center",
                  textShadow: "0px 2px 5px rgba(0, 0, 0, 0.05)",
                  fontSize: "21px",
                  fontStyle: "normal",
                  fontWeight: "700",
                  lineHeight: "36px",
                }}
              >
                Look no further! Our expert drivers are available in all over
                Kerala to get you where you need to go safely and on time.
              </Box>
            </Stack>
          </Stack>
        </Box>
      </Stack>
    </Stack>
  );
}
