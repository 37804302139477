export const setItem = async (tokenName, tokeValue) => {
  try {
    await localStorage.setItem(tokenName, tokeValue);
  } catch (e) {
    console.error(e);
  }
};

export const getItem = async (tokenName) => {
  try {
    const token = await localStorage.getItem(tokenName);
    return token;
  } catch (e) {
    console.error(e);
  }
};

export const removeItem = async (tokenName) => {
  try {
    await localStorage.removeItem(tokenName);
  } catch (e) {
    console.error(e);
  }
};
