import { Outlet, matchPath, useNavigate, useLocation } from "react-router-dom";
import { Stack } from "@mui/material";

const menuMaps = [
  {
    name: "Summary",
    path: "/admin/dashboard/summary",
  },
  {
    name: "Customers",
    path: "/admin/dashboard/customers",
  },
  {
    name: "Trips",
    path: "/admin/dashboard/trips",
  },
  {
    name: "Services",
    path: "/admin/dashboard/services",
  },
  // {
  //   name: "Cab Bookings",
  //   path: "/admin/dashboard/cabBook",
  // },
  // {
  //   name: "Car Wash Bookings",
  //   path: "/admin/dashboard/carWash",
  // },
  {
    name: "Drivers",
    path: "/admin/dashboard/drivers",
  },
  {
    name: "Cabs",
    path: "/admin/dashboard/cabs",
  },
];

export default function AdminDashboardLayout() {
  const navigate = useNavigate();
  const { pathname } = useLocation();
  return (
    <Stack
      gap={2}
      flex={1}
      sx={{
        overflow: "auto",
      }}
    >
      <Stack alignItems="start">
        <Stack
          sx={{
            borderRadius: "8px",
            border: "1px",
            background: "rgba(201, 228, 233, 1)",
            overflow: "hidden",
            height: 44,
          }}
          direction="row"
        >
          {menuMaps.map((mm) => (
            <Stack
              key={mm.name}
              justifyContent="center"
              sx={{
                padding: "0 24px",
                fontFamily: "Inter",
                fontSize: "14px",
                fontWeight: 500,
                cursor: "pointer",
                color: "rgba(52, 64, 84, 1)",

                ...(matchPath({ path: mm.path, end: false }, pathname) && {
                  background: "rgba(10, 77, 104, 1)",
                  color: "rgba(255, 255, 255, 1)",
                }),
              }}
              onClick={() => navigate(mm.path)}
            >
              {mm.name}
            </Stack>
          ))}
        </Stack>
      </Stack>
      <Outlet />
    </Stack>
  );
}
