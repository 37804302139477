import { Fragment, useEffect, useRef, useState } from "react";
import { useNavigate } from "react-router-dom";
import { Stack, Box, Divider, Typography } from "@mui/material";
import { formatDistanceToNow } from "date-fns";

import { getNotificationsDetails } from "_api_/notification";
import Loading from "components/Loading";
import MenuPopover from "components/MenuPopover";

import { ReactComponent as BellIcon } from "assets/bell.svg";
import { Icon } from "@iconify/react";

export function fToNow(date) {
  return formatDistanceToNow(new Date(date), {
    addSuffix: true,
  });
}

const typeMap = {
  CabBooking: "Cab Booking",
  TripBooking: "Trip Booking",
  CarWashBooking: "Car wash Booking",
};

const typeLinkMap = {
  CabBooking: "cabBook",
  TripBooking: "trip",
  CarWashBooking: "carWash",
};

export default function Notifications() {
  const navigate = useNavigate();

  const [open, setOpen] = useState(false);
  const [nCount, setNCount] = useState(0);
  const [nList, setNList] = useState([]);
  const [loading, setLoading] = useState(true);

  const anchorRef = useRef(null);
  const handleOpen = () => {
    setOpen(true);
  };
  const handleClose = () => {
    setOpen(false);
  };

  async function fetchData() {
    const { success, data, count } = await getNotificationsDetails();
    if (success) {
      setNCount(count);
      setNList(data);
    }
    setLoading(false);
  }

  useEffect(() => {
    fetchData();
    const intervalId = setInterval(fetchData, 20000);
    return () => clearInterval(intervalId);
  }, []);

  function handleClick(item) {
    navigate(`/admin/${typeLinkMap[item?.itemType]}/${item.itemId}`);
    setNCount(nCount - 1);
    setNList(nList.filter((n) => n.id !== item.id));
    handleClose();
  }

  return (
    <>
      <Stack
        sx={{
          width: "50px",
          height: "30px",
          padding: "2px, 8px, 2px, 8px",
          borderRadius: "20px",
          background: "rgba(10, 77, 104, 1)",
          cursor: "pointer",
        }}
        direction="row"
        alignItems="center"
        alignSelf="center"
        justifyContent="center"
        gap={1}
        onClick={handleOpen}
        ref={anchorRef}
      >
        <BellIcon stroke="white" width={15} />
        {!!nCount && (
          <Box
            sx={{
              color: "rgba(255, 255, 255, 1)",
              fontFamily: "Inter",
              fontSize: "12px",
              fontWeight: 500,
              textAlign: "center",
            }}
          >
            {nCount > 9 ? "9+" : nCount}
          </Box>
        )}
      </Stack>
      <MenuPopover
        open={open}
        onClose={handleClose}
        anchorEl={anchorRef.current}
        sx={{ width: 300 }}
      >
        {loading && (
          <Stack flex={1} p={2}>
            <Loading />
          </Stack>
        )}
        {!loading && !nList.length && (
          <Stack flex={1} p={2}>
            <Typography variant="subtitle1" noWrap textAlign="center">
              No new notifications
            </Typography>
          </Stack>
        )}
        {!loading &&
          nList.map((n) => (
            <Fragment key={n.id}>
              <Box
                sx={{ my: 1.5, px: 2.5, cursor: "pointer", flex: 1 }}
                onClick={() => handleClick(n)}
              >
                <Typography variant="subtitle2" noWrap>
                  {typeMap[n.itemType]}
                </Typography>
                <Typography variant="caption" sx={{ color: "text.secondary" }}>
                  {n.user?.fullName} made a new {typeMap[n.itemType]} (
                  {n.itemAppId})
                </Typography>
                <Typography
                  variant="caption"
                  sx={{
                    mt: 0.5,
                    display: "flex",
                    alignItems: "center",
                    color: "text.disabled",
                  }}
                >
                  {/* <Box
                    component={Icon}
                    icon={clockFill}
                    sx={{ mr: 0.5, width: 16, height: 16 }}
                  /> */}
                  <Icon
                    icon="eva:clock-outline"
                    sx={{ mr: 2, width: 16, height: 16 }}
                  />
                  {fToNow(n.createdAt)}
                </Typography>
              </Box>
              <Divider sx={{ my: 1 }} />
            </Fragment>
          ))}
      </MenuPopover>
    </>
  );
}
