import { Fragment } from "react";
import { matchPath, useNavigate, useParams } from "react-router-dom";
import { useLocation } from "react-router";

import { Box, Stack, Typography } from "@mui/material";

import RightArrow from "assets/right-arrow.svg";

const menuMaps = [
  {
    name: "Summary",
    path: "/admin/dashboard/summary",
    crumbs: [
      {
        name: "Dashboard",
      },
      {
        name: "Summary",
        path: "/admin/dashboard/summary",
      },
    ],
  },
  {
    name: "Customers",
    path: "/admin/dashboard/customers",
    crumbs: [
      {
        name: "Dashboard",
      },
      {
        name: "Customers",
        path: "/admin/dashboard/customers",
      },
    ],
  },
  {
    name: "Trips",
    path: "/admin/dashboard/trips",
    crumbs: [
      {
        name: "Dashboard",
      },
      {
        name: "Trips",
        path: "/admin/dashboard/trips",
      },
    ],
  },
  // {
  //   name: "Services",
  //   path: "/admin/dashboard/services/cabBook",
  //   crumbs: [
  //     {
  //       name: "Dashboard",
  //     },
  //     {
  //       name: "Services",
  //       path: "/admin/dashboard/services",
  //     },
  //   ],
  // },
  // {
  //   name: "Services",
  //   path: "/admin/dashboard/services/carWash",
  //   crumbs: [
  //     {
  //       name: "Dashboard",
  //     },
  //     {
  //       name: "Services",
  //       path: "/admin/dashboard/services",
  //     },
  //   ],
  // },
  {
    name: "Services",
    path: "/admin/dashboard/services",
    crumbs: [
      {
        name: "Dashboard",
      },
      {
        name: "Services",
        path: "/admin/dashboard/services",
      },
    ],
  },
  {
    name: "Drivers",
    path: "/admin/dashboard/drivers",
    crumbs: [
      {
        name: "Dashboard",
      },
      {
        name: "Drivers",
        path: "/admin/dashboard/drivers",
      },
    ],
  },
  {
    name: "Trips",
    path: "/admin/trip/:tripId",
    crumbs: [
      {
        name: "Dashboard",
      },
      {
        name: "Trips",
        path: "/admin/dashboard/trips",
      },
      {
        name: "tripId",
        format: (value) => `odd${value?.toString()?.padStart(4, "0")}`,
      },
    ],
  },
  {
    name: "Customer",
    path: "/admin/customer/:customerId",
    crumbs: [
      {
        name: "Dashboard",
      },
      {
        name: "Customers",
        path: "/admin/dashboard/customers",
      },
      {
        name: "customerId",
        format: (value) => `odduser${value?.toString()?.padStart(4, "0")}`,
      },
    ],
  },
  {
    name: "Customer Trips",
    path: "/admin/customer/:customerId/trips",
    crumbs: [
      {
        name: "Dashboard",
      },
      {
        name: "Trips",
        path: "/admin/dashboard/trips",
      },
      {
        name: "customerId",
        format: (value) => `odduser${value?.toString()?.padStart(4, "0")}`,
      },
    ],
  },
  {
    name: "Services",
    path: "/admin/carWash/:bookingId",
    crumbs: [
      {
        name: "Dashboard",
      },
      {
        name: "Services",
        path: "/admin/dashboard/services",
      },
      {
        name: "bookingId",
        format: (value) => `odd${value?.toString()?.padStart(4, "0")}`,
      },
    ],
  },
  {
    name: "Services",
    path: "/admin/cabBook/:bookingId",
    crumbs: [
      {
        name: "Dashboard",
      },
      {
        name: "Services",
        path: "/admin/dashboard/services",
      },
      {
        name: "bookingId",
        format: (value) => `oddcab${value?.toString()?.padStart(4, "0")}`,
      },
    ],
  },
  {
    name: "Cabs",
    path: "/admin/dashboard/cabs",
    crumbs: [
      {
        name: "Dashboard",
      },
      {
        name: "Cabs Details",
      },
    ],
  },
];

export default function AdminBreadcrumb() {
  const { pathname } = useLocation();
  const params = useParams();

  const navigate = useNavigate();

  const currentPage = menuMaps.find((mm) =>
    matchPath({ path: mm.path, end: true }, pathname)
  );

  const { crumbs = [] } = currentPage ?? {};

  const [firstElements, lastElement] =
    crumbs.length > 0
      ? [crumbs.slice(0, -1), crumbs.slice(-1)[0]]
      : [[], undefined];

  return (
    <Stack justifyContent="center" spacing={1}>
      <Stack flexDirection="row" gap={1}>
        {firstElements.map((c, i) => (
          <Fragment key={c.name}>
            {!!i && (
              <Box
                component="img"
                src={RightArrow}
                alt="Back"
                width="15px"
                height="15px"
              />
            )}
            <Stack
              sx={{
                fontFamily: "Inter",
                fontSize: "12px",
                fontWeight: 600,
                ...(c.path && { cursor: "pointer" }),
              }}
              onClick={() => c.path && navigate(c.path)}
            >
              {c.name}
            </Stack>
          </Fragment>
        ))}

        {lastElement && (
          <>
            <Box
              component="img"
              src={RightArrow}
              alt="Back"
              width="15px"
              height="15px"
            />
            <Stack
              sx={{
                fontFamily: "Inter",
                fontSize: "12px",
                fontWeight: 600,
                color: "rgba(10, 77, 104, 1)",
              }}
            >
              {lastElement.format
                ? lastElement.format?.(params[lastElement.name])
                : lastElement.name}
            </Stack>
          </>
        )}
      </Stack>

      <Typography
        variant="h5"
        sx={{
          fontFfamily: "Inter",
          fontSize: "25px",
          fontWeight: 500,
          textAlign: "left",
        }}
      >
        {currentPage?.name}
      </Typography>
    </Stack>
  );
}
