import { useLocation, useNavigate } from "react-router";
import { matchPath } from "react-router-dom";
import { Box, Stack, Typography } from "@mui/material";

import logo from "assets/logo2.png";
import user from "assets/user-pic.png";

import "./navbar.css";

const links = [
  {
    name: "Home",
    link: "/app/home",
  },
  {
    name: "Trips",
    link: "/app/trips",
  },

  {
    name: "About Us",
    link: "/app/about-us",
  },
];

export default function Navbar() {
  const { pathname } = useLocation();
  const navigate = useNavigate();

  return (
    <Stack className="header-bar">
      <Stack direction="row" alignItems="center" height="100%">
        <Box
          component="img"
          src={logo}
          sx={{
            height: 50,
            width: 90,
            cursor: "pointer",
          }}
          onClick={() => navigate("/app/home")}
        />
        <Stack
          direction="row"
          alignItems="center"
          spacing={1}
          height="100%"
          ml={10}
        >
          {links.map((l) => (
            <Stack
              className="menu-item"
              key={l.name}
              height="100%"
              justifyContent="center"
              width="130px"
              sx={{
                transform: "skew(20deg)",
                ...(l?.link &&
                  !!matchPath({ path: l?.link, end: true }, pathname) && {
                    backgroundColor: "#0a4d68",
                    color: "#fff",
                  }),
              }}
              onClick={() => navigate(l?.link)}
            >
              <Typography
                sx={{
                  transform: "skew(-20deg)",
                }}
              >
                {l.name}
              </Typography>
            </Stack>
          ))}
        </Stack>
        <Stack
          ml="auto"
          direction="row"
          spacing="10px"
          alignItems="center"
          onClick={() => navigate("/app/profile")}
          sx={{ cursor: "pointer" }}
        >
          <Box component="img" src={user} width="40px" height="40px" />
          <Stack>
            <Typography className="welcome-text">Welcome,</Typography>
            <Typography className="user-name-text">David Williams</Typography>
          </Stack>
        </Stack>
      </Stack>
    </Stack>
  );
}
