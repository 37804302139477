import { Outlet } from "react-router-dom";
import { Stack } from "@mui/material";

import Navbar from "./navbar/Navbar";
import Footer from "./footer/Footer";

export default function AppLayout() {
  return (
    <Stack height="100vh">
      <Stack height="80px">
        <Navbar />
      </Stack>
      <Stack flex={1} sx={{ overflow: "scroll" }}>
        <Outlet />
        <Footer />
      </Stack>
    </Stack>
  );
}
