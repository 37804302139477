import { styled } from "@mui/material/styles";
import { CircularProgress } from "@mui/material";

const RootStyle = styled("div")(({ theme }) => ({
  height: "100%",
  display: "flex",
  alignItems: "center",
  justifyContent: "center",
  backgroundColor: theme.palette.background?.default,
}));

export default function Loading({ ...other }) {
  return (
    <RootStyle {...other}>
      <CircularProgress style={{ color: "rgba(10, 77, 104, 1)" }} />
    </RootStyle>
  );
}
